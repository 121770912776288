//google map  
module.exports = (function() {
    return{
        init: function(){
            var map = new google.maps.Map(document.getElementById('googlemap'), {
            center:  {lat: 7.878529, lng:  98.392086},
            scrollwheel: false,
            zoom: 15,
            // disableDefaultUI: true
            });
            var image = new google.maps.MarkerImage('assets/img/marker.svg',
                    new google.maps.Size(50, 50),
                    new google.maps.Point(0,0),
                    new google.maps.Point(15, 25),
                    new google.maps.Size(30, 30));
            var myMarker = new google.maps.Marker({
                position: {lat: 7.877992, lng: 98.393905},
                map: map,
                icon: image
            });
        }
    }
}());