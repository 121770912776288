//pos4et summi yslygi
module.exports = (function() {
	return {
		shopping: function(){
		var filtergroup = $('.shopping__filter-group'),
			filterFor = filtergroup.filter('#group-for').find('.shopping__filter-radio:checked'),
			filterTime = filtergroup.filter('#group-time').find('.shopping__filter-radio:checked'),
		    filterForValue = filterFor.val(),
		    filterTimeValue = filterTime.val();
		var list = $('.shopping__list'),
			items = list.find('.shopping__item');	
		items.show().hide();
		items.filter(function(index) {			
			if(typeof filterForValue === 'undefined'){
				return $(this).data('time') == filterTimeValue;
			}
			else if (typeof filterTimeValue === 'undefined'){
				return  $(this).data('for') == filterForValue;
			}
			else{
				return $(this).data('for') == filterForValue && $(this).data('time') == filterTimeValue;	
			}
			
		}).show();
		},
		auto: function(){
			var filtergroup = $('.arenda__filter-group'),
				filterClass = filtergroup.filter('#group-class').find('.arenda__filter-radio:checked'),
				filterType= filtergroup.filter('#group-type').find('.arenda__filter-radio:checked'),
			    filterClassValue = filterClass.val(),
			    filterTypeValue = filterType.val();
			var list = $('.arenda__list'),
				items = list.find('.arenda__item');	
			items.show().hide();
			items.filter(function(index) {			
				if(typeof filterClassValue === 'undefined'){
					return $(this).data('type') == filterTypeValue;
				}
				else if (typeof filterTypeValue === 'undefined'){
					return  $(this).data('class') == filterClassValue;
				}
				else{
					return $(this).data('type') == filterTypeValue && $(this).data('class') == filterClassValue;	
				}
				
			}).show();	
		}
	}	
}());