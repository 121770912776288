//pos4et summi yslygi
module.exports = (function() {
	return {
		init: function(){
			var prnts = $('input[name=parents]'),
			prntPrice = parseInt(prnts.data('price')),
			chldrn = $('input[name=children]'),
			chldrnPrice = parseInt(chldrn.data('price')),
			peopleSum = parseInt(chldrn.val())+parseInt(prnts.val()),
			dops = $('.order__form-adds').find('div'),
			dopPrice = 0,		
			checkSum = parseInt(chldrn.val())*chldrnPrice + parseInt(prnts.val())*prntPrice;
			if($('.tabs').find('#order.arenda').length){
				var price = parseInt($('.price').data('price'));
			    var from = $('#from').datepicker('getDate');
		    	var to = $('#to').datepicker('getDate');    
				var days = Math.ceil((to - from) / (1000 * 60 * 60 * 24) + 1);
				var dateFrom = $('#from').val();
				var dateTo = $('#to').val();
				$(".date-from").text(dateFrom)
				$(".date-to").text(dateTo)
			    $('.order-item-days').text(days + ' ');
			    $('.check-sum').text(days*price + '$');	
			    $('.input-totalprice').val(days*price + '$');
			}
			else if($('.tabs').find('#order.realty').length){
			    var from = $('#from').datepicker('getDate');
		    	var to = $('#to').datepicker('getDate');    
		    	var days = Math.ceil((to - from) / (1000 * 60 * 60 * 24) + 1);
		    	var dateFrom = $('#from').val();
				var dateTo = $('#to').val();
		    	$(".date-from").text(dateFrom)
				$(".date-to").text(dateTo)
			    $('.order-item-days').text(days + ' ');
			}
			else if($('.tabs').find('#order.realty-long').length){
			    var date =  $('#date').val(),
			    	month = $('.month__count').val();
		    	$(".date").text(date);
		    	$('.order__form-month').find('.month').text(' ' + month);	


			}	
			else if($('.tabs').find('#order.realty-double').length){
				var  switchBtn =  $('.order__form').not('.hide').hasClass('long') ? 'long' : 'short';
			    if(switchBtn == 'long'){
			    	var date =  $('#date').val(),
			    	month = $('.month__count').val();
			    	$(".date").text(date);
			    	$('.order__form-month').find('.month').text(' ' + month);	
			    }
			    else if(switchBtn == 'short'){
			    	var from = $('#from').datepicker('getDate');
			    	var to = $('#to').datepicker('getDate');    
			    	var days = Math.ceil((to - from) / (1000 * 60 * 60 * 24) + 1);
			    	var dateFrom = $('#from').val();
					var dateTo = $('#to').val();

			    	$(".date-from").text(dateFrom)
					$(".date-to").text(dateTo)
				    $('.order-item-days').text(days + ' ');
			    }
			}
			else if($('.tabs').find('#order.photo').length){
				var date =  $('#date').val(),
					price =  parseInt($('.photo__price').data('price'));
				 $('.date').text(date);
				 $('.check-sum').text(price + '$');			 
			}
			else if($('.flowers-single').length){
				if($('.flowers-single.rose').length || $('.flowers-single.orchid').length){
					var price = $('.flowers__price').data('price'),
						date =  $('#date').val(),
						amount = $('input[name=amount]'),
						amountCount = amount.val(),
						amountPrice = parseInt(amount.data('price')),
						height = $('input[name=height]'),
						heightCount = height.val(),
						heightPrice = parseInt(height.data('price')),
						sum = (((heightCount-50)/10)*heightPrice + amountPrice)*amountCount;
					$('.order-item-flowers-amount').text(amountCount)
					$('.order-item-flowers-height').text(heightCount + ' ')
					$('.date').text(date);
					$('.check-sum').text(sum + '$')

				}
				else{
					var type =  $('.flowers__type-link.active'),
					price = type.data('price'),
					date =  $('#date').val();
					$('.flowers__price').find('span').text(price+ '$');
					$('.order-item-flowers-type').text($(type).text());
					$('.check-sum').text(price + '$')	
					$('.date').text(date);
				}
			}
			else{
				$('.people-parents').text('' + prnts.val());
				$('.people-children').text('' + chldrn.val());
				$('.people-sum').text('' +peopleSum )
				dops.each(function(index, el) {	
					dopPrice += parseInt($(el).data('price'));
				});
				$('.check-sum').text(checkSum+dopPrice + '$')	
			}			
		}
	}	
}());