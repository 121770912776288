//PARALLAX  
module.exports = (function() {
    var heroContainer = $('.hero').find('.container'),  
        casesBg = $(".cases-bg");

    return {
        move: function (block, windowScroll, strafeAmount) {
            var strafe = windowScroll / -strafeAmount + '%';
            block.css('transform', 'translate3d(0,' + strafe + ',0)');
            block.css('webkitTransform', 'translate3d(0,' + strafe + ',0)');
        },
        init: function (wScroll) {
            this.move(heroContainer, wScroll, 10);
            this.move(casesBg, wScroll, 20);
        }
    }    
}());