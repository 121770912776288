//realty map  
module.exports = (function() {
    return{
        init: function(){
            var lat = $('#realty__map').data('lat'),
                lng = $('#realty__map').data('lng');
            var realtmap = new google.maps.Map(document.getElementById('realty__map'), {
            center:  {lat: lat, lng: lng},
            scrollwheel: false,
            zoom: 15,
            // disableDefaultUI: true
            });
            var realtmapMarker = new google.maps.MarkerImage('assets/img/marker.svg',
                    new google.maps.Size(50, 50),
                    new google.maps.Point(0,0),
                    new google.maps.Point(15, 25),
                    new google.maps.Size(30, 30));
            var myMarker = new google.maps.Marker({
                position: {lat: lat, lng: lng},
                map: realtmap,
                icon: realtmapMarker,
            });
        }
    }
}());